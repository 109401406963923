.search {
    position: relative;;
}
.search-bar {
    background-color: var(--white);
    padding: 8px 12px;
    border-radius: var(--radius);
    position: relative;

    > input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 2rem;
    }

    .btn.clear {
        background-color: transparent;
        position: absolute;
        right: 0px;
        top: 7px;
        font-size: 1.6rem;
        color: var(--grey);

        &:hover {
            .icon svg line {
                stroke: var(--red);
            }
        }

        .icon {
            svg line {
                stroke: var(--grey);
                stroke-width: 6px;
                transition: 0.25s linear;
            }
        }
    }
}

