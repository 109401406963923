@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
:root {
    --white: #fff;
    --black: #000;
    --grey: #a2a2a2;
    --red: #ff0000;
    --radius: 12px;
}
html {
    font-size: 62.5%;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    background-color: #f2f2f2;
}

input {
    font-family: 'Open Sans', sans-serif;
}
.btn {
    border: none;
    cursor: pointer;
}
