/* list */
.list {
    opacity: 0;
    background-color: var(--white);
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    border-radius: var(--radius);
    overflow-y: auto;
    transition: 0.15s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .item {
        font-size: 2rem;
        padding: 6px 12px;
        transition: 0.15s linear;

        &:hover {
            color: var(--white);
            font-weight: 600;
            background-color:rgb(255, 174, 0);
        }

        &.active {
            color: var(--white);
            background-color:rgb(255, 174, 0);
            .highlight {
                background-color: transparent;
            }
        }
    }

    .highlight {
        background-color: rgb(255, 215, 222);
    }
}
